import {
  QueryClient as TanstackQueryClient,
  QueryClientProvider as TanstackReactQueryClientProvider
} from "@tanstack/react-query";
import { ReactNode } from "react";

import { client } from "@packages/case-management-queries";
import MessageBus from "@packages/message-bus";

MessageBus.subscribe("auth.session", (message, { session }) => {
  const accessToken = session.accessToken.jwtToken;
  client.setConfig({ headers: { Authorization: `Bearer ${accessToken}` } });
});

/** For supporting `@packages/case-management-queries` */
const tanstackQueryClient = new TanstackQueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0,
      refetchOnWindowFocus: false
    }
  }
});

export function CaseManagementProviders({ children }: { children: ReactNode }) {
  return (
    <TanstackReactQueryClientProvider client={tanstackQueryClient}>
      {children}
    </TanstackReactQueryClientProvider>
  );
}
