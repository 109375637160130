import { Auth } from "@packages/service-api";
import { ReactNode, useEffect, useState } from "react";
import { client as caseManagementClient } from "@packages/case-management-queries";
import { client as acceleratorClient } from "@packages/accelerator-queries";

type Session = Awaited<ReturnType<typeof Auth.getSession>>;

/**
 * This is a naive approach of ensuring that an access token is set for the
 * `@packages/*-queries` clients before performing any queries.
 *
 * @TODO This logic will eventually be moved to some core packaged and managed by apps.
 * @TODO Handle refresh token / retry logic.
 */
export function CaseManagementSpaAuthTokenProvider({
  fallback,
  children
}: {
  fallback?: ReactNode;
  children: ReactNode;
}) {
  const [session, setSession] = useState<null | Session>(null);

  // on mount, ensure session is loaded and client is configured
  // before rendering children that may trigger fetches
  useEffect(() => {
    async function get() {
      const session = await Auth.getSession();
      if (session) {
        const accessToken = session.getAccessToken().getJwtToken();
        caseManagementClient.setConfig({ headers: { Authorization: `Bearer ${accessToken}` } });
        acceleratorClient.setConfig({ headers: { Authorization: `Bearer ${accessToken}` } });
      }

      setSession(session);
    }
    get();
  }, []);

  if (!session) {
    return fallback;
  }

  return children;
}
